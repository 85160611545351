import React , {useState, useEffect, Suspense} from 'react';
import Loading from '../components/Loading'
import { prof } from "../portfolio";
import {get} from 'axios';
import GithubProfileCard from 'components/GithubProfileCard';

const GithubProfile = () => {
    return ( 
        <Suspense fallback={<Loading />}>
           <GithubProfileCard prof={prof}/>
        </Suspense>
     );
}
 
export default GithubProfile;