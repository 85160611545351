import emoji from "react-easy-emoji";

import googlelogo from "./assets/img/icons/common/google.svg";
import github from "./assets/img/icons/common/github.svg";

import marketiveLogo from "./assets/img/logos/marketive.png";
import lynxLogo from "./assets/img/logos/lynx.jpg";
import FurtherLogo from "./assets/img/logos/further.png";
import camelcodingLogo from "./assets/img/logos/camelcoding.jpg";

import vueLogo from "./assets/img/logos/vue.png";
import laravelLogo from "./assets/img/logos/laravel.png";
import bulmaLogo from "./assets/img/logos/bulma.png";
import pixiLogo from "./assets/img/logos/pixijs.png";
import threejsLogo from "./assets/img/logos/threejs.png";
import unityLogo from "./assets/img/logos/unity.svg";

import theRide from "./assets/img/projects/theride.png";
import restaurant from "./assets/img/projects/restaurant.png";
import fitness from "./assets/img/projects/fitness.png";
import quiz from "./assets/img/projects/quiz.png";
import credentialCalculator from "./assets/img/projects/mandatumkalkulator.png";
import wape from "./assets/img/projects/wape.png";

import sirReginald from "./assets/img/projects/sirReginald.png";
import soldierOfOrban from "./assets/img/projects/soldierOfOrban.jpg";
import speedyHamby from "./assets/img/projects/speedyHamby.png";
import keysOfRhapsody from "./assets/img/projects/keysOfRhapsody.png";
import quickDrawDuel from "./assets/img/projects/quickDrawDuel.png";
import lolMemoryGame from "./assets/img/projects/lolMemoryGame.png";
import spaceCommander from "./assets/img/projects/spaceCommander.png";
import bomberDodger from "./assets/img/projects/bomberDodger.png";
import blackjack from "./assets/img/projects/blackjack.png";
import glados from "./assets/img/projects/glados.png";

import vuePixi from "./assets/img/projects/vuePixi.png";

import panzerVideo from "./assets/videos/panzer.mkv";
import pirateVideo from "./assets/videos/pirate.mkv";
import pianoVideo from "./assets/videos/piano.mkv";


export const greetings = {
	name: "Benedek Szabolcs",
	title: "Hi all, I'm Szabi",
	description:
		"A passionate Full Stack Web and Game Developer having an experience of building Web applications with JavaScript / VueJS / PixiJS / Laravel / Firebase and some other cool libraries and frameworks.",
	resumeLink: "https://drive.google.com/file/d/1mHcnVRdhZ5PjI3T0mmrCMuyMBPjYVhUs/view?usp=sharing",
};

export const openSource = {
	githubUserName: "benedek1239",
};

export const contact = {};

export const socialLinks = {
	facebook: "https://www.facebook.com/szabi.benedek",
	github: "https://github.com/benedek1239",
	linkedin: "https://www.linkedin.com/in/szabolcs-benedek-3187431b5/",
};

export const skillsSection = {
	title: "What I do",
	subTitle:
		" FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
	skills: [
			" ♦ Develop highly interactive and responsive Front end / User Interfaces for your web and mobile applications",
			" ♦ Developing Browser and Desktop Games with JavaScript or Unity",
			" ♦ Using Back end Frameworks, Libraries and Technologies"
	],

	softwareSkills: [
		{
			skillName: "html-5",
			fontAwesomeClassname: "vscode-icons:file-type-html",
		},
		{
			skillName: "css3",
			fontAwesomeClassname: "vscode-icons:file-type-css",
		},
		{
			skillName: "sass",
			fontAwesomeClassname: "logos:sass",
		},
		{
			skillName: "JavaScript",
			fontAwesomeClassname: "logos:javascript",
		},
		{
			skillName: "TypeScript",
			fontAwesomeClassname: "logos:typescript-icon",
		},
		{
			skillName: "npm",
			fontAwesomeClassname: "vscode-icons:file-type-npm",
		},
		{
			skillName: "sql-database",
			fontAwesomeClassname: "vscode-icons:file-type-sql",
		},
		{
			skillName: "mongoDB",
			fontAwesomeClassname: "vscode-icons:file-type-mongo",
		},
		{
			skillName: "firebase",
			fontAwesomeClassname: "logos:firebase",
		},
		{
			skillName: "git",
			fontAwesomeClassname: "logos:git-icon",
		}
	],
	
	moreSoftwareSkills: [
		{
			skillName: "vuejs",
			skillLogo: vueLogo,
		},
		{
			skillName: "Laravel",
			skillLogo: laravelLogo,
		},
		{
			skillName: "bulma",
			skillLogo: bulmaLogo,
		},
		{
			skillName: "pixijs",
			skillLogo: pixiLogo,
		},
		{
			skillName: "Unity",
			skillLogo: unityLogo,
		},
        {
			skillName: "ThreeJS",
			skillLogo: threejsLogo,
		},
	]

};

export const SkillBars = [
	{
		Stack: "Frontend",
		progressPercentage: "85", 
	},
	{
		Stack: "Backend",
		progressPercentage: "60",
	},
	{
		Stack: "Game Programming",
		progressPercentage: "70",
	},
];

export const educationInfo = [
	{
		schoolName: "Sapientia University",
		subHeader: "Computer Science",
		duration: "September 2017 - June 2021",
		desc: "Graduated with a degree in electrical engineering.",
		descBullets: [
			"Learning both Hardware and Software of digital devices",
			"Better knowledge of Frameworks and Libraries",
			"Possibility to join internship programs",
		],
	},
	{
		schoolName: "Joannes Kajoni",
		subHeader: "Tourism and English",
		duration: "September 2013 - June 2017",
		desc: "Got the graduation diploma and finished english thesis.",
		descBullets: [
			"Learning english grammar and communication",
			"I got to know the tourism industry",
			"Learned how to work with many different people"
		],
	},
];

export const experience = [
    {
		role: "Game Developer",
		company: "Camel Coding",
		companylogo: camelcodingLogo,
		date: "May 2022 – Present",
		desc: "We are developing online casino games in Pixi.js, with a unique game engine, that we also developed. We are forming a great Transylvanian team.",
		url: "https://www.camelcoding.com/"
	},
	{
		role: "Full-Stack Developer",
		company: "GoFurther Digital",
		companylogo: FurtherLogo,
		date: "February 2021 – May 2022",
		desc: "Working with multiple Clients, developing multiple Applications with the newest technologies in a great team. We mostly developed Laravel/Vue apps.",
		url: "https://gofurther.digital/en"
	},
	{
		role: "Web Developer Intern",
		company: "The Marketive",
		companylogo: marketiveLogo,
		date: "July 2020 – Sept 2020",
		desc: "Me and a friend of mine developed an online travel companion finder Application in Laravel and met a professional team in our home city.",
		url: "https://themarketive.com/"
	},
	{
		role: "Web Developer Intern",
		company: "Lynx Solutions",
		companylogo: lynxLogo,
		date: "July 2019 – Sept 2019",
		desc: "We formed a team with students from university and developed a React Application. This app was a complex, multi-functional teaching interface.",
		url: "https://www.lynxsolutions.eu/"
	},
];

export const projects = [
	{
		name: "The Ride",
		desc: "Online travel companion finder Application, made in Laravel. This website was my state exam at Sapientia university.",
		img: theRide,
		link: "https://github.com/benedek1239/TheRide",
	},
	{
		name: "Game of Thrones quiz",
		desc: "Highly interactive quiz applcication, for hardcore Game of Thrones fans. Made for fun.",
		img: quiz,
		link: "https://quiz-c4bfb.firebaseapp.com/",
	},
	{
		name: "Restaurant Webpage",
		desc: "Site of a restaurant in my home town, where users can order food. Made for a friend.",
		img: restaurant,
		link: "https://restaurant-hosting.web.app/",
	},
    {
		name: "Credential Calculator",
		desc: "Crecential Calculator for the 2022 Hungarian parliamentary elections.",
		img: credentialCalculator,
		link: "https://mandatumkalkulator.atlatszo.hu",
	},
    {
		name: "Airwape Product Page",
		desc: "A single page product site for selling airwapes, including javascript aniamtions on mobile.",
		img: wape,
		link: "https://airvapeusa.com/products/airvape-legacy-pro",
	},
	{
		name: "GYM Webpage",
		desc: "A website for GYM owners and for their clients to managing tickets. It was a university project from .Net course.",
		img: fitness,
		link: "https://github.com/benedek1239/Fitness",
	},

];

export const games = [
	{
		name: "Bomber Dodger",
		desc: "Player vs player stategy, action game in a friendly environment. Inspired by the classic Bomberman.",
		img: bomberDodger,
		link: "https://benedekszabolcs.itch.io/bomber-dodger",
	},
	{
		name: "Project GLaDOS",
		desc: "A brainstorming and problem-solving dice-placing game, which is a digital version of the board game Doom Machine.",
		img: glados,
		link: "https://project-glados.web.app/",
	},
	{
		name: "Sir Reginald",
		desc: "Adventure Role Playing Game with a kind knight sir Reginald. He wants to save his pride, Lady Magdolna.",
		img: sirReginald,
		link: "https://benedekszabolcs.itch.io/crusader",
	},
    {
		name: "Quickdraw Duel",
		desc: "A skill-based card game where you have to be faster then the AI to deplete your deck first.",
		img: quickDrawDuel,
		link: "https://benedekszabolcs.itch.io/quickdraw-duel",
	},
	{
		name: "The Soldier of Viktor Orban",
		desc: "Top-down zombie shooter game, but this time the player needs to help Viktor Orban to defend the border of Hungary.",
		img: soldierOfOrban,
		link: "https://benedekszabolcs.itch.io/the-soldier-of-orban-viktor",
	},
    {
		name: "Special Black Jack",
		desc: "Real Time Strategy Online PvP Card Game. It's not a simple Black Jack, Special Cards makes the Gameplay more fun.",
		img: blackjack,
		link: "https://specialblackjack.herokuapp.com/",
	},

	{
		name: "Space Bounty",
		desc: "Classic Shootem Up styled Space Ship Game. The Bounty Hunter needs to eliminate the Big Pirate Boss and his Bandits.",
		img: spaceCommander,
		link: "https://benedekszabolcs.itch.io/space-bounty/",
	},
	{
		name: "Speedy Hamby",
		desc: "A Hamburger maker Game, where the player must use his speed and attention if he wants to do the order well.",
		img: speedyHamby,
		link: "https://benedekszabolcs.itch.io/speedy-hamby",
	},
	{
		name: "Keys of Rhapsody",
		desc: "An interactive piano rhythm game that requires a lot of practice. Playable with MIDI keyboard.",
		img: keysOfRhapsody,
		link: "https://benedekszabolcs.itch.io/keys-of-rhapsody",
	},
	{
		name: "LoL Memory Game",
		desc: "Classic Card Memory Game, with LoL Champions and Hearthstone cards, because I love both of this games.",
		img: lolMemoryGame,
		link: "https://benedekszabolcs.itch.io/league-of-legends-memory-game",
	},

];

export const upworks = [
	{
		desc: "Requirements: Component loads a floorplan image. Markers are placed over the floorplan according to x, y positions. Component needs to respond to window resizing and needs to be responsive. Markers need to respond to hover and click events. Click will open a new link. Hover will perform some animation on the marker and show a popup. Some of the markers needs to have a pulse effect.",
		img: vuePixi,
		link: 'https://floorplan-cfebd.web.app/#/'
	}

];


export const designs = [
	{
		name: "Pirate",
		desc: "A Pirate style, really atmospheric menu, for a future Pirate game. The inspiration came from a great song, the Wellerman.",
		video: pirateVideo,
	},
	{
		name: "Panzer",
		desc: "The inspiration behind this plan, is an old panzer game I used to play with when I was a kid. I stole the logo from World of Tanks.",
		video: panzerVideo,
	},
	{
		name: "Piano",
		desc: "A relaxing menu with an actually working rhythm game. The game works only with my Midi Piano Controller. Made it for myself to make it easier to learn to play the piano ",
		video: pianoVideo,
	},

];


export const prof = {
	avatar_url: "https://avatars.githubusercontent.com/u/52738472?s=400&u=2bf1862e90599d6454d998f3369d519b0b9c0977&v=4",
	location: "Csíkszereda",
	linkedin: "https://www.linkedin.com/in/szabolcs-benedek-3187431b5/",
};
