import React from 'react';
import build from "../assets/lottie/build";

import {
    Container,
    Row,
    Progress,
    Col
} from "reactstrap";



const Cube = () => {
    return ( 
        <Container className="section section-lg text-center my-5 cube-margins">
			<h1 className="h1 cube-header-aligner">My favourites</h1>
			<div className="slider">
				<div className="container-cube">
					<div className="slide x"></div>
					<div className="slide y"></div>
					<div className="slide z"></div>
				</div>
				<div className="cube-shadow"></div>
			</div>
        </Container>
     );
}
 
export default Cube;