import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Navigation from "./components/Navigation";
import Greetings from "./containers/Greetings";
import Skills from "./containers/Skills";
import Proficiency from "./containers/Proficiency";
import Cube from "./containers/Cube";
import Education from "./containers/Education";
import Experience from "./containers/Experience";
import Projects from "./containers/Projects";
import Games from "./containers/Games";
import Upworks from "./containers/Upworks";
import Cloud from "./containers/Cloud";
import Room from "./containers/Room";
import Designs from "./containers/Designs";
import GithubProfile from "./containers/GithubProfile";

const App = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route
					path="/"
					exact
					render={(props) => (
						<>
							<Navigation />
							<Greetings />
							<Cloud />
							<Skills />
							<Proficiency />
							<Cube />
							<Education />
							<Experience />
							<Projects />
							<Games />
							<Upworks />
							{/* <Room /> */}
							<GithubProfile />
						</>
					)}
				/>
			</Switch>
		</BrowserRouter>
	);
};

export default App;
