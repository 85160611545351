import React from "react";

import { Card, CardBody, CardHeader, Col, Button } from "reactstrap";

import { Fade } from "react-reveal";

const ProjectsCard = ({ data }) => {
	return (
		<Col lg="6">
			<Fade bottom duration={1000} distance="40px">
				<Card className="shadow-lg--hover shadow mt-4">
				<CardHeader className="p-0 upwork-header">
					<img src={data.img} className="w-100 rounded-top"/>
                </CardHeader>
					<CardBody className="pl-2 py-3">
						<div className="d-flex p-0">
							<div className="">
								<p className="description mt-3">{data.desc}</p>
                                <Button
                                    className="btn-icon"
                                    color="success"
                                    href={data.link}
                                    target="_blank"
                                >
                                    <span className="btn-inner--icon">
                                        <i className="fa fa-arrow-right mr-2" />
                                    </span>
                                    <span className="nav-link-inner--text ml-1">
                                        Prototype
                                    </span>
                                </Button>
							</div>
						</div>
					</CardBody>
				</Card>
			</Fade>
		</Col>
	);
};

export default ProjectsCard;
